import React,{useState,useEffect} from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import Meta from "@components/meta"
import BottomCta from "@components/bottom-cta"
import '@styles/pages/privacy.scss'

import posed, { PoseGroup } from 'react-pose'
const transition = {
  visible: { opacity: 1,delay: 600, },
  hidden: { opacity: 0,delay: 600}
}
const Box = posed.div(transition)


const Privacy = props => {
  const [path, setPath] = useState(false);

  useEffect(()=> {
    setPath(true);
    return ()=> setPath(false);
  })
  return (
      <Layout>
        <Meta
          title="私たちについて"
          desc="KnockLearnの私たちについてのページです。"
        />
        <PoseGroup>
          <Box key={"about"} pose={path ? "visible" : "hidden"}>
          <section class="page">
              <div class="page-top">
                <div class="page-top__container">
                  <ol>
                    <li><Link to="/">TOP&nbsp;>&nbsp;</Link></li>
                    <li>プライバシーポリシー</li>
                  </ol>
                  <h2 class="page-top__heading">Privacy Policy</h2>
                  <p class="page-top__lead">プライバシーポリシー</p>
                </div>
              </div>
              <div class="section">
                <div class="section__container">
                  <div class="about-privacy">
                    <h3>
                      Privacy Policy
                    </h3>
                    <p>
                        KnockLearn（以下，「当社」といいます。）は，提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシーを定めます。<br/>
                        <br/><br/>
                        ◯基本方針<br/>
                        当社は、個人情報の適切な取扱いと保護のために「個人情報の保護に関する法律」を遵守します。<br/><br/>

                        <span className="bold">1.個人情報</span><br/>
                        「個人情報」とは、生存する個人に関する情報であって、次の各号のいずれかに該当するものをいう。<br/>
                        <br/>
                        一 当該情報に含まれる氏名、生年月日その他の記述等（文書、図画若しくは電磁的記録（電磁的方式（電子的方式、磁気的方式その他人の知覚によっては認識することができない方式をいう。）で作られる記録をいう。）に記載され、若しくは記録され、又は音声、動作その他の方法を用いて表された一切の事項（個人識別符号を除く。）をいう。）により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができる
                        こととなるものを含む。）<br/>
                        二 個人識別符号が含まれるもの<br/><br/>

                        <span className="bold">2.個人情報の取得方法</span><br/>
                        当社は，お客様が利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号などの個人情報をお尋ねすることがあります。当社では、事項3で特定した利用目的の達成に必要な範囲で、適正かつ適法な手段により、お客さまの個人情報を取得いたします。<br/>
                        <br/>
                        <span className="bold">3.個人情報の利用目的</span><br/>
                        当社は、お客様からご提示いただいた名前、住所、電話番号、性別その他の個人情報を、以下の目的のために利用させていただきます。<br/>
                        A）授業に関するご連絡のため<br/>
                        B）面談・イベントへの参加のお誘いおよびご出欠確認のため<br/>
                        C）月謝やテキストなどに関する事務連絡のため<br/>
                        D）インタビュー記事の掲載のため<br/>
                        E）上記の利用目的に付随する目的<br/><br/>

                        <span className="bold">4.利用目的の変更</span><br/>
                        当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。利用目的の変更を行った場合には，変更後の目的について，本ウェブサイト上に公表するものとします。<br/>
                        <br/>
                        <span className="bold">5.個人情報の管理方法</span><br/>
                        当社は個人情報の紛失・改ざんおよび漏えい等防止のため、適切な安全管理措置を実施し安全性の確保に努めます。<br/>
                        <br/>
                        <span className="bold">6.個人情報の第三者提供</span><br/>
                        1.当社は，次に掲げる場合を除いて，あらかじめお客様の同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。<br/>
                        一 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき。<br/>
                        二 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき。<br/>
                        三 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。<br/>
                        四 予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき。<br/>
                        <div className="indent">
                            1.利用目的に第三者への提供を含むこと<br/>
                          2.第三者に提供されるデータの項目<br/>
                          3.第三者への提供の手段または方法<br/>
                          4.本人の求めに応じて個人情報の第三者への提供を停止すること<br/>
                          5.本人の求めを受け付ける方法<br/>
                        </div>
                        <br/>
                        2.当社は、前項に掲げる事項を変更する場合は、変更する内容について、あらかじめ、本人に通知し、又は本人が容易に知り得る状態に置くことに努めます。<br/>
                        <br/>
                        3.次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。<br/>
                        一 個人情報取扱事業者が利用目的の達成に必要な範囲内において個人データの取扱いの全部又は一部を委託する場合<br/>
                        二 合併その他の事由による事業の承継に伴って個人データが提供される場合<br/>
                        三 個人データを特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人データの項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人データの管理について責任を有する者の氏名又は名称について、あらかじめ、本人に通知し、又は本人が容易に知り得る状態に置いているとき。<br/>
                        <br/>
                        <span className="bold">7.個人情報の開示</span><br/>
                        当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし、開示することで下記のようなリスクが生じる場合、情報の全部または一部を開示しません。なお、情報の全部または一部を開示しない場合、あるいは開示請求された情報が存在しない場合は、その旨を本人に対して速やかに通知します。<br/>
                        一 本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合<br/>
                        二 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合<br/>
                        三 その他法令に違反することとなる場合<br/>
                        <br/>
                        <span className="bold">8.個人情報の訂正および削除</span><br/>
                        当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。<br/>
                        また当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをお客様に通知します。<br/>
                        <br/>
                        <span className="bold">9.プライバシーポリシーの変更</span><br/>
                        本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，お客様に通知することなく，変更することができるものとします。<br/>
                        また当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。<br/>
                        <br/>
                        <span className="bold">10.お問い合わせ窓口</span><br/>
                        本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。<br/>
                        <br/>
                        住所：〒211-0002 神奈川県川崎市中原区上丸子山王町2-1316-21<br/>
                        社名：KnockLearn<br/>
                        Eメールアドレス：info@knocklearn.com<br/>

                    </p>
                  </div>
                  </div>
                  </div>
            </section>
          <BottomCta/>
          </Box>
        </PoseGroup>
      </Layout>
  )
}
export default Privacy
